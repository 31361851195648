import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

import store from '@/store'
import { apiTour } from '@/api'
import { STATUS_TOUR } from '@/constants/tour'

import useToast from '@useToast'

import tourStoreModule from '../tourStoreModule'
import { checkStatusRequestPaymentFull } from '../useTourHandle'

export default function useTourBookingListHandle() {
  const { toastError } = useToast()

  if (!store.hasModule('app-tour')) {
    store.registerModule('app-tour', tourStoreModule)
  }
  // Register module
  if (!store.hasModule('app-tour')) store.registerModule('app-tour', tourStoreModule)
  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule('app-tour')) store.unregisterModule('app-tour')
  })

  const refTourListTable = ref(null)
  const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

  const sizePerPage = ref(40)
  const totalTickets = ref(0)
  const currentPage = ref(1)

  const dataMeta = computed(() => {
    const localItemsCount = refTourListTable.value
      ? refTourListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTickets.value,
    }
  })

  // Table Handlers
  const tableColumns = [
    { label: 'no', key: 'no' },
    { label: 'bookingCode', key: 'bookingCode' },
    { label: 'status', key: 'status' },
    // { label: 'customerName', key: 'customerName' },
    // { label: 'customerName', key: 'customerName' },
    { label: 'tourName', key: 'tourName' },
    { label: 'startDate', key: 'startDate' },
    { label: 'depositPrice', key: 'depositPrice' },
    { label: 'totalPrice', key: 'totalPrice' },
    { label: 'totalCommission', key: 'totalCommission' },
    // { label: 'totalPriceToBePaid', key: 'totalPriceToBePaid' },
    // { label: 'totalPriceRefund', key: 'totalPriceRefund' },
    { label: 'createdAt', key: 'createdAt', sortable: true },
    { label: 'createdBy', key: 'createdBy' },
    { label: 'note', key: 'note' },
    {
      label: 'actions', key: 'actions', stickyColumn: true, class: 'position-right-0',
    },
  ]

  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  // Filter
  const startDateFilter = ref(null)
  const endDateFilter = ref(null)
  const startTripDateFilter = ref(null)
  const endTripDateFilter = ref(null)
  const createdByFilter = ref(null)
  const agencyCodeFilter = ref(null)
  const searchTextFilter = ref(null)
  const bookingCodeFilter = ref(null)
  const statusFilter = ref(null)

  // Empty filter
  const isEmptyFilter = computed(
    () => !!(
      startDateFilter.value === null
        && endDateFilter.value === null
        && startTripDateFilter.value === null
        && endTripDateFilter.value === null
        && createdByFilter.value === null
        && agencyCodeFilter.value === null
        && searchTextFilter.value === null
        && bookingCodeFilter.value === null
        && statusFilter.value === null
    ),
  )

  const refetchData = () => {
    if (refTourListTable.value) {
      refTourListTable.value.refresh()
    }
  }

  const clearFilter = () => {
    startDateFilter.value = null
    endDateFilter.value = null
    startTripDateFilter.value = null
    endTripDateFilter.value = null
    createdByFilter.value = null
    agencyCodeFilter.value = null
    searchTextFilter.value = null
    bookingCodeFilter.value = null
    statusFilter.value = null
    refetchData()
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, searchTextFilter, bookingCodeFilter],
    () => {
      refetchData()
    },
  )

  const loading = ref(false)

  async function fetchBookingList() {
    loading.value = true
    const params = {
      size: sizePerPage.value,
      page: currentPage.value,
      searchText: searchTextFilter.value ?? undefined,
      bookingCode: bookingCodeFilter.value ?? undefined,
      createdById: createdByFilter.value?.id ?? undefined,
      agencyCode: agencyCodeFilter.value?.agencyCode ?? undefined,
      startDate: startDateFilter.value ? `${startDateFilter.value}` : undefined,
      endDate: endDateFilter.value ? `${endDateFilter.value}` : undefined,
      startTripDate: startTripDateFilter.value ? `${startTripDateFilter.value}` : undefined,
      endTripDate: endTripDateFilter.value ? `${endTripDateFilter.value}` : undefined,
      status: statusFilter.value ?? undefined,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
    }

    try {
      const { data } = await apiTour.fetchBookingList(params)
      totalTickets.value = data.total
      return data.items.map(booking => ({
        ...booking,
        realStatus: checkStatusRequestPaymentFull(booking) ? STATUS_TOUR.REQUEST_PAYMENT_FULL : booking.status,
      }))
    } catch (error) {
      console.error(error)
      toastError({
        title: 'messagesList.error',
        content: 'Tải danh sách booking thất bại',
      })
      return []
    } finally {
      loading.value = false
    }
  }

  const disabledCopy = ref(false)
  const { copy } = useClipboard()

  function copyText(text) {
    disabledCopy.value = true
    setTimeout(() => {
      disabledCopy.value = false
    }, 1000)

    copy(text)
      .then(() => {
        this.$root.toastSuccess('reservation.sms.copySuccess')
      })
      .catch(() => {
        this.$root.toastError('reservation.sms.copyFail')
      })
  }

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchBookingList,
    tableColumns,
    isRoleF1,
    currentPage,
    dataMeta,
    sortBy,
    isSortDirDesc,
    refTourListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    copyText,
    clearFilter,

    sizePerPage,
    totalTickets,
    disabledCopy,
    statusFilter,
    startDateFilter,
    endDateFilter,
    startTripDateFilter,
    endTripDateFilter,
    createdByFilter,
    agencyCodeFilter,
    searchTextFilter,
    bookingCodeFilter,
    isEmptyFilter,
  }
}
